<template>
    <div>

    </div>
</template>

<script>
    export default {
        created () {
            this.$router.push( {name: "Ticket", query: { shareQR: true }} );
        },
    }
</script>

<style lang="scss" scoped>

</style>